//Helpers
/////////////////////////////////////////////////////////////////
.tt-u {
    text-transform: uppercase;
}

.ta-c {
    text-align: center;
}

.p-r {
    position: relative;
}

.d-none {
    display: none;
}

.ta-c-mobile {

    @media(max-width: 575px){
        text-align: center;
    }
}

.post__list {

    li {

        & + li {
            margin-top: 10px;
        }

        a {
            color: var(--c-red);
            font-size: 18px;
            font-weight: 500;
        }
    }
}

.post__list-link {
    margin-top: 1rem;
    a {
        color: var(--c-red);
        font-size: 18px;
        font-weight: 600;
    }
}

//Texts
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@for $i from 1 through 5 {
    .fs-#{$i} { font-size: var(--fs-#{$i}); }
}

//Titles
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@for $i from 1 through 5 {
    .hs-#{$i} { font-size: var(--hs-#{$i}); }
}

[class*="hs-"] {
    font-weight: 600;
    color: var(--c-title-color);
    line-height: 1.25;
}

//Spaces
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
// @each $name, $var in $spaces {
//     .mt-#{$name} { margin-top: var(#{$var}); }
//     .mb-#{$name} { margin-bottom: var(#{$var}); }
// }

//Ui link
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ui__link {
    position: relative;

    display: inline-block;

    text-decoration: none;

    --ui-link-color: #1E1E1E;

    &:before {
        content: "";

        position: absolute;

        left: 0;
        bottom: 0;

        width: 100%;
        height: 1px;

        transition: transform 0.3s ease;
        transform-origin: top left;

        background-color: var(--ui-link-color);
    }

    &:hover {

        &:before {
            transform: scaleX(0);
            transform-origin: top right;
        }
    }
}