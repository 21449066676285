// FAQ
/////////////////////////////////////////////////////////////////////////////////

.questions__container {
    padding-bottom: 1.5rem;
}

.questions__group-item {

    & + .questions__group-item {
        margin-top: 40px;
    }
}

.question__group-title {
    font-size: 34px;
    font-weight: 500;
    color: var(--c-red);
    margin-bottom: 20px;
}

.question__item {

    & + .question__item {
        margin-top: 1rem;
    }

    .question__header-btn {
        display: block;

        width: 100%;

        padding: 20px 25px;

        border: 1px solid rgba(205, 205, 205, 1);

        font-size: 22px;
        font-weight: 600;
        text-align: left;

        cursor: pointer;
    }
}

.question__body {
    padding: 20px 25px;

    border: 1px solid rgba(205, 205, 205, 1);
    border-top: none;

    display: none;
}