// Article 
///////////////////////////////////////////////////////////////////////////////////////////

.article {

    a {

        &:hover {

            .article__content {
                transform: translateX(-15px);
            }
        }
    }
}

.article__image {
    position: relative;

    overflow: hidden;

    aspect-ratio: 10/4;

    img {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
    }
}

.article__content {
    position: relative;

    margin-top: -45px;
    right: -15px;

    width: 100%;

    padding: 20px 50px 50px 20px;

    background-color: var(--c-white);

    box-shadow: 3px 3px 15px rgba(153, 151, 153, 0.5);

    transition: transform 0.3s ease;

    @media (max-width: 575px) {
		margin: 0;
        right: 0;
	}
}

.article__content-description {
    color: var(--c-title-color);
    font-weight: 400;
    line-height: 1.6em;
    min-height: 90px;
    margin-top: 25px;
}

//Verse
/////////////////////////////////////////////////////////////////////////

.article__verse {
    margin-top: 25px;
}

.article__verse a {
    display: grid;
    grid-template-columns: 35% 65%;

    @media (max-width: 575px) {
		grid-template-columns: 100%;
	}

    &:hover {

        .article__verse-content {
            transform: translateY(-10px);
        }
    }
}

.article__verse-image {
    position: relative;

    overflow: hidden;

    aspect-ratio: 16/9;

    @media (max-width: 575px) {
		aspect-ratio: 10/4;
	}

    img {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
    }
}

.article__verse-content {
    padding: 15px 20px 30px 15px;

    background-color: var(--c-white);

    box-shadow: 3px 3px 15px rgba(153, 151, 153, 0.5);

    transition: transform 0.3s ease;

    margin-right: -15px;
    margin-bottom: -10px;
    margin-top: 10px;
    margin-left: -15px;

    position: relative;

    @media (max-width: 575px) {
		margin: 0;
	}
}