// Pagination
///////////////////////////////////////////////////////////////////////////////////////////////////
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 5px;

    width: 100%;

    margin-top: 50px;
}

.pagination a, .pagination span {
    font-size: 18px;
    font-weight: 500;
    padding: 5px 15px 6px;
    background-color: var(--c-white);
    text-decoration: none;
    color: var(--c-title-color);

    transition: all 0.3s ease;

    &.current, &:hover {
        background-color: var(--c-red);
        color: var(--c-white);
    }
}

.pagination__pages {
    display: flex;
    align-items: center;
    gap: 5px;
}