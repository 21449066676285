// Categories
///////////////////////////////////////////////////////////////////////////////////////////////////
.categories__grid {
    --item-per-line: 2;

    display: grid;
    grid-template-columns: repeat(var(--item-per-line), 1fr);
    row-gap: 50px;
    column-gap: 30px;

    background-color: var(--c-background-color);

    @media(max-width: 767px){
        --item-per-line: 1;
    }
}

.category__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.category__item-title-wrapper {
    height: 90px;

    display: flex;
    align-items: center;
}

.category__item-title {
    font-size: 40px;
    line-height: 42px;
    font-weight: 600;
    color: var(--c-title-color);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.category__item-articles {
    flex: 1;
    margin-top: 20px;
}

.category__item-link {
    margin-top: 35px;
}