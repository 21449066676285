// Buttons
/////////////////////////////////////////////////////////////////////////////////////
.button__primary {
    font-weight: bold;
    
    font-size: 22px;

    transition: color 0.3s ease;

    &.--red {
        color: var(--c-red);

        &:hover {
            color: var(--c-text-color);
        }
    }
}

.button {
    padding: 10px 15px;
    border-radius: 4px;
    text-transform: uppercase;
    color: var(--c-white);
    background-color: #F5A623;
    font-weight: 600;
    display: inline-block;
}