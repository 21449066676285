/* Fonts */
$roboto: 'roboto', Arial, Helvetica, sans-serif;
$ubuntu: 'ubuntu', Arial, Helvetica, sans-serif;

/* Colors */
$black: #000000;
$white: #ffffff;
$grey: #555555;
$grey-light: #c4c4c4;
$blue: #11379e;
$red: #ad1919;
$green: #198619;

/* Body */
$text: $black;
$background: $white;

/* Dimensions */
$innerWidth: 1240px;
$innerWidth-sm: 720px;
$innerPadding: 20px;
$innerPadding-mobile: 10px;

:root {

    //layout
    ///////////////////////////////
    --container-width: 100%;

    //Fonts
    ///////////////////////////////
    --ff-default: 'Affogato', Arial, Helvetica, sans-serif;
    --fs-default: 1rem;

    --fs-1: 18px;
    --fs-2: 16px;
    --fs-3: 14px;
    --fs-4: 12px;
    --fs-5: 8px;

    //Title
    ///////////////////////////////
    --hs-1: clamp(2.125rem, 2vw + 1rem, 2.813rem);
    --hs-2: clamp(1.625rem, 1vw + 1rem, 2rem);;
    --hs-3: 21px;
    --hs-4: 18px;
    --hs-5: 14px;

    //colors
    ///////////////////////////////
    --c-white: #FFFFFF;
    --c-black: #000000;

    --c-title-color: #383e42;
    --c-background-color: #f2f2f2;
    --c-red: #e20b12;
    --c-link-color: #e20b12;

    //responsive
    ///////////////////////////////
    @media (min-width: 576px) {
      --container-width: 540px;
    }

    @media (min-width: 768px) {
		--container-width: 720px;
	}

    @media (min-width: 992px) {
		--container-width: 960px;
	}

    @media (min-width: 1200px) {
		--container-width: 1140px;
	}

}