// Hero
///////////////////////////////////////////////////////////////////////////////////////////////////
.hero__inner {
    position: relative;

    height: 400px;
    width: 100%;

    overflow: hidden;
}

.hero__inner-image {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
}