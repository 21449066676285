// Videos
////////////////////////////////////////////////////////////////////////////////////
.videos__controls {
    display: flex;
    align-items: center;

    @media(min-width: 992px){
        display: none;
    }

    li {
        
        & + li {
            margin-left: 10px;
        }

        img {
            width: 40px;
        }
    }
}

.videos__caption {

    background-color: var(--c-white);
    height: 100px;
    position: relative;
    padding: 15px;
    font-size: 15px;
    font-weight: bold;

}

.videos__iframe {
    position: relative;

    aspect-ratio: 16/9;

    iframe {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }
}