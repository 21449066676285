// Header
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////

.header__top {
    font-size: 18px;
    font-weight: 500;
    background-color: #f2f2f2;
    color: var(--c-title-color);

    @media(max-width: 991px){
        display: none;
    }
}

.header__top-grid {
    display: flex;
    align-items: center;
}

.header__top-website {
    padding-top: 3px;

    p {
        margin-bottom: 0;
    }

    a {
        color: var(--c-link-color);
        font-weight: 600;

        &:hover {
            color: #383e42;
        }
    }
}

.header__top-blog {
    display: inline-block;

    padding: 10px 20px;

    background-color: var(--c-white);

    margin: 5px 20px 0;

    border-radius: 6px 6px 0 0;
}

.header__top-store {
    padding-top: 3px;

    a {
        display: flex;
        align-items: center;

        color: var(--c-link-color);
        font-weight: 600;

        span {
            display: flex;
            align-items: center;
        }

        img {
            width: 15px;
            margin-right: 8px;
        }

        &:hover {
            color: #383e42;
        }
    }
}

// Middle
//////////////////////////////////////////////////////////

.header__middle {
    padding: 15px 0;
    background-color: var(--c-white);

    @media(max-width: 991px){
        padding: 0;

        .container {
            max-width: 100%;

            padding-left: 0;
        }
    }
}

.header__middle-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
}

.header__middle-burger {
    @media(min-width: 992px){
        display: none;
    }
}

.header__middle-burger-btn {
    display: inline-block;

    width: 48px;
    height: 48px;

    background-color: var(--c-red);

    padding: 14px 10px;

    .burger__btn-inner {
        position: relative;

        width: 100%;
        height: 100%;
    }

    span {
        position: absolute;

        left: 0;

        width: 100%;
        height: 3px;

        border-radius: 10px;

        background-color: var(--c-white);

        transition: 0.3s ease;

        &:nth-child(1){
            top: 0;
        }

        &:nth-child(2){
            top: calc(50% - 1px);
        }

        &:nth-child(3){
            top: calc(100% - 2px);
        }

    }

    &.--active {

        span {
            &:nth-child(1){
                top: 50%;
                transform: rotate(45deg);
            }
    
            &:nth-child(2){
               display: none;
            }
    
            &:nth-child(3){
                top: 50%;
                transform: rotate(-45deg);
            }
        }
    }

}

.header__middle-logo-link {
    display: flex;
    align-items: center;

    color: var(--c-title-color);

    font-size: 40px;
    font-weight: 600;

    @media(max-width: 991px){
        font-size: 24px;
    }

    img {
        width: 170px;
        margin-left: 15px;
        margin-top: 2px;

        @media(max-width: 991px){
            width: 100px;
            margin-left: 8px;
            margin-top: 0;
        }
    }
}

.header__middle-form {

    @media(max-width: 991px){
        position: absolute;

        left: 0;
        top: 100%;

        padding: 15px;

        width: calc(100% + 15px);

        background-color: var(--c-white);

        z-index: 1;

        display: none;
    }

    .searchform__container {
        position: relative;
    }

    .search__input {
        color: var(--c-title-color);
        background-color: var(--c-white);
        padding: 12px 45px 12px 12px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;

        width: 350px;

        font-size: 18px;

        &::placeholder {
            color: #6c757d;
            font-weight: 100;
            font-style: normal;
        }

        @media(max-width: 991px){
            width: 100%;
        }
    }

    button {
        position: absolute;

        right: 0;
        top: 0;
        bottom: 0;

        padding-right: 10px;

        display: flex;
        align-items: center;
    }
}

.header__middle-search {

    @media(min-width: 992px){
        display: none;
    }

     button {
        display: flex;
        align-items: center;
     }
}

// Bottom
////////////////////////////////////////////////////////////////

.header__bottom {
    background-color: var(--c-red);

    @media(max-width: 991px){
        display: none;
    }
}

.header__bottom-nav {
    position: relative;

    & > ul {
        display: flex;
        align-items: center;

        & > li {

            & > a {
                display: inline-block;

                color: var(--c-white);

                font-weight: 500;
                font-size: 18px;

                padding: 0.75rem 1.5rem;

                text-transform: uppercase;

                &.--active {
                    background-color: var(--c-white);
                    color: var(--c-red);
                }
            }
        }
    }
}

.header__nav-drop {
    position: absolute;
    
    left: 0;
    top: 100%;

    z-index: 1;

    width: 100%;

    background: var(--c-white);

    padding: 30px;

    display: flex;
    flex-wrap: wrap;

    display: none;

    &.--active {
        display: block;
    }
}

.header__nav-drop-item {
    display: inline-block;
    width: 33%;

    margin-bottom: 25px;

    float: left;

    & > a {
        font-weight: 600;
        font-size: 18px;
        color: var(--c-title-color);

        &:hover {
            color: var(--c-red);
        }
    }

    &.--archive-item {
        width: 100%;
        margin-bottom: 30px;

        a {
            font-size: 22px;
            text-transform: uppercase;
        }
    }
}

.header__subnav-drop-link {
    font-size: 18px;
    color: #383e42;
}

.header__subnav-drop-item {
    margin-top: 10px;
}

// Mobile navigation
///////////////////////////////////////////////////////////////////////

.menu__navigation {
    position: fixed;

    top: 48px;
    left: 0;

    width: 100%;
    height: calc(100% - 50px);

    background-color: var(--c-white);

    padding: 30px;

    z-index: 9;
    
    display: none;

    overflow: auto;

    &.--active {
        display: block;
    }

    @media(min-width: 992px){
        display: none;
    }

    & > ul {

        & > li {

            &.mobile__nav-store {

                a {
                    display: flex;
                    align-items: center;
            
                    color: var(--c-title-color);
                    font-weight: 500;

                    text-transform: none;

                    &::before {
                        display: none;
                    }
            
                    span {
                        display: flex;
                        align-items: center;
                    }
            
                    img {
                        width: 15px;
                        margin-right: 8px;
                    }
            
                    &:hover {
                        color: #383e42;
                    }
                }
            }

            & + li {
                margin-top: 20px;
            }

            & > a {
                position: relative;

                display: block;

                font-size: 25px;
                font-weight: 600;
                text-transform: uppercase;

                color: var(--c-title-color);

                &.--active {

                    &:before {
                        transform: rotate(90deg);
                    }
                }

                &:before {
                    content: "";

                    position: absolute;

                    right: 0;
                    top: 0;

                    width: 30px;
                    height: 30px;

                    background-image: url('../../../public/images/caret-menu.svg');
                    background-size: 30px;
                    background-position: center center;
                    background-repeat: no-repeat;

                    transition: transform 0.3s ease;
                }
            }
        }
    }
}

.mobile__nav-website {
    font-size: 25px;
    color: var(--c-title-color);
    font-weight: 500;

    p {
        margin-bottom: 0;
    }

    a {
        color: var(--c-link-color);
        font-weight: 600;

        &:hover {
            color: #383e42;
        }
    }
}

.mobile__nav-drop-link {
    font-size: 25px;
    font-weight: 600;

    color: var(--c-title-color);
}

.mobile__nav-drop {
    padding: 15px 30px;

    display: none;

    li {

        & + li {
            margin-top: 20px;
        }

        &.--archive-item {

            a {
                color: var(--c-link-color);
            }
        }
    }
}