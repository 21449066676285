// Image
////////////////////////////////////////////////////////////////////////////////////////

.image__link {
    display: block;

    &:hover {
        .image__el {
            box-shadow: none;
        }
    }
}

.image__el {
    width: 100%;

    box-shadow: 3px 3px 15px 0px rgba(153,151,153,.5);

    transition: .3s ease-in-out;
}