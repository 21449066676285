@mixin label($color: $black) {
    display: block;
    color: $color;
    margin-bottom: 15px;
    @include fontsize(20);

    @include tablet {
        @include fontsize(16);
    }
}

@mixin placeholder($color: $black) {
    color: $color !important;
    @include fontsize(20);
    font-weight: 400;
    opacity: 1 !important;
    text-overflow: ellipsis !important;
    transition: .2s color ease-out;

    @include tablet {
        @include fontsize(16);
    }
}

@mixin input($color: $black) {
    display: block;
    color: $color;
    font-family: $roboto;
    @include fontsize(20);
    width: 100%;
    padding: 10px;
    border: 2px solid $color;
    border-radius: 10px;
    background-color: transparent;
    resize: vertical !important;
    transition: .2s border-color ease-out, .2s outline ease-out;

    &:hover {
        border-color: scale-color($color, $lightness: 20%);
    }

    &:focus {
        outline: 0;
        border-color: scale-color($color, $lightness: 40%);
    }

    &::placeholder {
        @include placeholder(scale-color($color, $lightness: 50%));
    }

    @include tablet {
        @include fontsize(16);
    }
}

@mixin select($color: $black) {
    display: block;
    color: $color;
    font-family: $roboto;
    @include fontsize(20);
    font-weight: 400;
    padding: 15px 50px 15px 30px;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid $color;
    cursor: pointer;
    transition: .2s border-color ease-out;

    appearance: none;
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00LjU4ODI5IDcuNzY5NkM0LjYzNDE2IDcuODQwNjYgNC42OTU1NSA3Ljg5ODc1IDQuNzY3MTUgNy45Mzg4OUM0LjgzODc1IDcuOTc5MDMgNC45MTg0MyA4IDQuOTk5MzIgOEM1LjA4MDIxIDggNS4xNTk4OSA3Ljk3OTAzIDUuMjMxNSA3LjkzODg5QzUuMzAzMSA3Ljg5ODc1IDUuMzY0NDggNy44NDA2NiA1LjQxMDM1IDcuNzY5Nkw5LjkxMDcxIDAuODM2NzQzQzkuOTYyOCAwLjc1Njc3OCA5Ljk5MzM1IDAuNjYzMTE4IDkuOTk5MDMgMC41NjU5MzhDMTAuMDA0NyAwLjQ2ODc1NyA5Ljk4NTMyIDAuMzcxNzczIDkuOTQyOTUgMC4yODU1MjRDOS45MDA1OCAwLjE5OTI3NCA5LjgzNjg2IDAuMTI3MDU3IDkuNzU4NzEgMC4wNzY3MTkxQzkuNjgwNTYgMC4wMjYzODExIDkuNTkwOTcgLTAuMDAwMTUyNzA2IDkuNDk5NjcgNi42MTEwM2UtMDdIMC40OTg5N0MwLjQwNzg4NSAwLjAwMDQwMTkxOCAwLjMxODYyNSAwLjAyNzI3NyAwLjI0MDc5IDAuMDc3NzM1NUMwLjE2Mjk1NSAwLjEyODE5NCAwLjA5OTQ4OTkgMC4yMDAzMjcgMC4wNTcyMTk2IDAuMjg2Mzc3QzAuMDE0OTQ5MyAwLjM3MjQyNyAtMC4wMDQ1MjY5NCAwLjQ2OTEzOSAwLjAwMDg4NTQ2OCAwLjU2NjExMkMwLjAwNjI5Nzg3IDAuNjYzMDg0IDAuMDM2Mzk0MSAwLjc1NjY0OCAwLjA4NzkzNzYgMC44MzY3NDNMNC41ODgyOSA3Ljc2OTZaIiBmaWxsPSIjMkUyNDhDIi8+Cjwvc3ZnPgo=) no-repeat top 53% right 23px;
    background-size: 10px;


    &:hover {
        border-color: scale-color($color, $lightness: 20%);
    }
    &:focus {
        outline: 0;
        border-color: scale-color($color, $lightness: 40%);
    }

    @include tablet {
        @include fontsize(16);
    }
}

@mixin radio {

    $radioWidth: 22px;
    $radioHeight: 22px;

    $radioOffsetTop: 2px;
    $radioOffsetLeft: 0;

    $radioInnerWidth: 14px;
    $radioInnerHeight: 14px;

    $radioInnerOffsetTop: $radioOffsetTop + ($radioHeight - $radioInnerHeight) / 2;
    $radioInnerOffsetLeft: ($radioWidth - $radioInnerWidth) / 2;

    position: relative;

    &:before {
        display: block;
        position: absolute;
        content: '';
        top: $radioOffsetTop;
        left: $radioOffsetLeft;
        width: $radioWidth;
        height: $radioHeight;
        border: solid 2px $blue;
        border-radius: 50%;
        background-color: transparent;
        z-index: 1;
        box-sizing: border-box;
        transition: .15s all ease-out;
    }

    &:after {
        opacity: 0;
        visibility: hidden;
        display: block;
        position: absolute;
        content: '';
        top: $radioInnerOffsetTop;
        left: $radioInnerOffsetLeft;
        width: $radioInnerWidth;
        height: $radioInnerHeight;
        border: solid 2px $blue;
        border-radius: 50%;
        background-color: $blue;
        box-sizing: border-box;
        z-index: 2;
        transform: scale(0, 0);
        transition: .15s all ease-out;
    }

    &.checked {

        &:before {
            border: solid 1px $green;
            background-color: $green;
        }

        &:after {
            opacity: 1;
            visibility: visible;
            transform: scale(1, 1);
        }
    }

    & input[type="radio"] {
        position: absolute;
        left: -9999px;
    }

    & label {
        cursor: pointer;
        @include fontsize(20);
        padding: 0 0 0 #{$radioWidth + 6};
        margin: 0;
        z-index: 3;
        vertical-align: middle;
    }

}

/**************************************************
* AUTOFILL
**************************************************/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $grey !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    background-color: transparent !important;
}
