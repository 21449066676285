.single__content {

    .container, .component__header-inner {
        padding: 0;
    }

    .component__header-inner {
        margin-bottom: 15px;
    }
}

.single__title {
    margin-top: 15px;
    margin-bottom: 25px;
}

.single__tags {
    margin-top: 30px;
    font-weight: bold;
}

.tag__link {
    color: var(--c-link-color);
}