// Hero
///////////////////////////////////////////////////////////////////////////////////////////////////

.introduction__inner {
    background-color: var(--c-white);
    padding: 30px;

    &.--no-x {
        padding: 30px 0;
    }
}

.introduction__text {
    margin-top: 10px;
    color: rgba(33, 37, 41, 1);
    font-size: 20px;
}