.footer {
	background-color: #f3f2f2;
}

.footer__middle {
	padding: 60px 0 40px;
}

.footer__nav-grid {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	justify-content: center;
	gap: 40px;

	@media(max-width: 767px){
		grid-template-columns: 100%;
		gap: 20px;
	}
}

.footer__nav-title {
	position: relative;

	font-size: 22px;
    font-weight: 900;
	color: var(--c-title-color);

	&:after {
		content: "";

		position: absolute;

		right: 0;
		top: -10px;
		bottom: 0;
		left: auto;

		margin: auto;

		width: 20px;
		height: 20px;

		border-bottom: solid 1px var(--c-title-color);
		border-left: solid 1px var(--c-title-color);

		transform: rotate(-45deg);

		display: none;
	}

	@media(max-width: 767px){
		cursor: pointer;

		&:after {
			display: block;
		}
	}
}

.footer__nav-drop {
	padding-top: 20px;

	@media(max-width: 767px){
		display: none;
	}
}

.footer__nav-drop-item {

	& + .footer__nav-drop-item {
		margin-top: 10px;
	}
}

.footer__nav-drop-link {
	font-size: 1.125rem;

	&:hover {
		text-decoration: underline;
	}
}

.footer__reinsurance ul {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, calc(25% - 30px)));
	justify-content: space-between;

	@media(max-width: 767px){
		grid-template-columns: 100%;
	}

	@media(min-width: 768px) and (max-width: 991px){
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

.footer__reinsurance-item {
	display: flex;
	align-items: center;

	padding: 15px 0;

	font-size: 20px;
	color: #999;
	font-weight: 900;

	.picto {
		position: relative;

		margin-right: 15px;

		min-width: 45px;
		height: 45px;

		img {
			position: absolute;

			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			object-fit: contain;
		}
	}
}

.footer__bottom {
	padding: 40px 0 80px;
}

.footer__bottom-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media(max-width: 767px){
		flex-direction: column;
		align-items: center;
	}
}

.footer__bottom-logo {
	text-align: center;

	@media(max-width: 767px){
		margin-bottom: 30px;
	}

	img {
		width: 220px;
	}
}

.footer__bottom-copyright {
	font-size: 12px;
	margin-top: 5px;
}

.footer__bottom-networks ul {
	display: flex;
	align-items: center;

	li {

		& + li {
			margin-left: 20px;
		}
	}
}