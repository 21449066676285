/***** Structure du site *****/
body {
	line-height: 1.2; font-weight: 400; text-size-adjust: 100%;
	font-family: var(--ff-default);
	color: $text;
	background-color: var(--c-background-color);

	&.--hidden {
		overflow: hidden;
	}
}

//container
//////////////////////////////////////////////////////////
.container {
	width: 100%;

    padding-right: 15px;
    padding-left: 15px;

    margin-right: auto;
    margin-left: auto;

	max-width: var(--container-width);
}

//components
//////////////////////////////////////////////////////////

.component {

	& + .component {
		margin-top: 40px;
	}
}

.component__header-inner{
	padding: 10px 20px 20px;
	background-color: var(--c-white);

	&.--verse {
		background-color: transparent;
		padding: 0 0px 20px;
	}

	&.--flexed {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.component__footer {
	margin-top: 30px;
}

//contents
///////////////////////////////////////////////////////////
.contents {
	position: relative;

	margin-top: -60px;

	padding-bottom: 100px;

	&.--no-margin {
		margin-top: 0;
	}
}

//Layout
////////////////////////////////////////////////////////////
.layout__grid {
	display: grid;
	grid-template-columns: minmax(0, 70%) minmax(0, 30%);
	gap: 30px;

	margin-top: 30px;

	@media(max-width: 767px){
		grid-template-columns: 100%;
	}
}

.layout__main-inner {
	background-color: var(--c-white);

	padding: 20px 35px;
}

.layout__container {
	background-color: var(--c-white);

	padding: 20px 35px;

	@media(max-width: 570px){
		padding: 10px 20px;
	}
}

//breadcrumbs
///////////////////////////////////////////////////////////
.breadcrumbs {

	color: var(--c-title-color);

	text-transform: uppercase;

	font-weight: bold;

	margin-bottom: 5px;

	a {
		color: var(--c-red);
	}
}