// Magazine
///////////////////////////////////////////////////////////////////////////////////////////////////
/// 

.magazine__grid {
    display: grid;
    grid-template-columns: minmax(180px, 20%) minmax(0, 80%);

    box-shadow: 3px 3px 15px 0px rgba(153, 151, 153, 0.5);

    background-color: var(--c-white);

    @media(max-width: 575px){
        grid-template-columns: 100%;
    }
}

.magazine__image {
    position: relative;

    overflow: hidden;

    height: 100%;
    min-width: 180px;

    display: flex;
    align-items: center;


    img {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;

        @media(max-width: 575px){
            position: relative;
        }
    }
}

.magazine__content {
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.magazine__content-title {
    color: var(--c-title-color);
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.magazine__content-link {
    margin-top: 1rem;
}