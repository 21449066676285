// Sidebar
//////////////////////////////////////////////////////////////
.sidebar {
    padding: 20px 30px;

    margin-bottom: 30px;

    background-color: var(--c-white);

    .component__header-inner, .container {
        padding: 0;
    }

    .component__header-inner {
        margin-bottom: 20px;
    }

    .hs-1 {
        font-size: var(--hs-2);
    }
}