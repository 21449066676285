// Offers
///////////////////////////////////////////////////////////////////////////////////////////////////

.offers {
    padding: 30px 0;
    background-color: var(--c-white);
}

.offers__grid {
    --item-per-line: 3;

    display: grid;
    grid-template-columns: repeat(var(--item-per-line), 1fr);
    gap: 30px;

    @media(max-width: 575px){
        --item-per-line: 1;
    }
}

.offers__item-image {
    position: relative;

    text-align: center;

    aspect-ratio: 16/9;

    img {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        object-fit: cover;
    }
}

.offers__item-title {
    font-weight: 600;
    margin-top: 0.5rem;
}