// Products
///////////////////////////////////////////////////////////////////////////////////////////////////

.products {
    padding: 40px 0 50px;
    background-color: var(--c-white);
}

.products__grid {
    display: grid;
    grid-template-columns: repeat(3, (minmax(0,1fr)));
    gap: 30px;

    margin-top: 10px;

    @media(max-width: 767px){
        grid-template-columns: 100%;
    }
}

.product__item-image {
    position: relative;

    aspect-ratio: 16/9;

    margin-bottom: 20px;

    img {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;
    }
}

.product__item-content {
    text-align: center;
}

.product__item-content-title {
    font-weight: 600;
    color: var(--c-title-color);
    font-size: 18px;
    margin-bottom: 30px;
}