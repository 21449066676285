// Highlighted posts
///////////////////////////////////////////////////////////////////////////////////////////////////
.highlighted__post {

    &.--no-container {
        
        .container {
            padding: 0;
        }
    }

    &.--small {

        .highlighted__post-grid {
            --grid-height: 400px;

            @media (max-width: 767px) {
                --grid-height: auto;
            }
        }
    }
}

.highlighted__post-grid {
    --grid-height: 550px;
    --item-per-line: 3;

    height: var(--grid-height);
    
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (max-width: 575px) {
		--item-per-line: 1;
        --grid-height: auto;
        flex-direction: row;
	}

    @media (min-width: 576px)  and (max-width: 767px) {
		--item-per-line: 2;
        --grid-height: auto;
        flex-direction: row;
	}

    &.--small-verse {
        height: auto;

        display: grid;
        grid-template-columns: repeat(var(--items-verse), 1fr);

        @media (max-width: 575px) {
            --items-verse: 1 !important;
        }

        .highlighted__post-item {
            width: 100%;
            height: auto !important;

            aspect-ratio: 16/9;

            max-height: 350px;
        }
    }

    .highlighted__post-item {
        width: calc(100% / var(--item-per-line));

        // 1 and 6 child
        &:nth-child(1), &:nth-child(6){
            height: calc(var(--grid-height) * 0.6);

            @media (max-width: 767px) {
                height: auto;
            }
        }

        // 3 and 4 child
        &:nth-child(3), &:nth-child(4){
            height: calc(var(--grid-height) * 0.4);

            @media (max-width: 767px) {
                height: auto;
            }
        }

        // 2 and 5 child
        &:nth-child(2), &:nth-child(5){
            height: calc(var(--grid-height) * 0.5);

            @media (max-width: 767px) {
                height: auto;
            }
        }

        /* Re-order items into rows */
        @media(min-width: 768px){
            &:nth-child(3n+1) { order: 1; }
            &:nth-child(3n+2) { order: 2; }
            &:nth-child(3n)   { order: 3; }
        }

        @media (max-width: 767px) {
            height: auto;
            aspect-ratio: 16/11;
        }
    }
}

.highlighted__post-item-link {
    position: relative;

    display: block;

    width: 100%;
    height: 100%;

    &:hover {
        
        .highlighted__post-item-title {
            transform: translate(0,-15px);
        }
    }
}

.highlighted__post-item-img {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    &:after {
        content: "";

        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%) center center;
    }

    img {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
    }
}

.highlighted__post-item-title {
    position: absolute;

    left: 0;
    bottom: 0;

    font-size: 18px;
    color: var(--c-white);

    padding: 0 10px 10px;

    transition: .3s ease-in-out;
}