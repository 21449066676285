// Form
///////////////////////////////////////////////////////////////////////
.form__wrapper {
    background-color: #E0E0E0;
    padding: 30px;
}

.form__container {

    &.--submited {

        .form__suggestion {
            display: none;
        }

        .form__container-success {
            display: block;
        }
    }
}

.form__container-success {
    text-align: center;
    background-color: var(--c-white);
    padding: 20px;
    color: #27ae60;
    font-weight: 600;
    border-left: solid 5px #27ae60;
    border-radius: 4px;
    margin-top: 30px;

    display: none;
}

.form__suggestion {
    display: flex;
    flex-wrap: wrap;

    margin-top: 20px;
}

.form__group {
    margin-bottom: 15px;

    width: 100%;

    &.--mid {
        width: 50%;

        @media(max-width: 767px){
            width: 100%;
        }
    }
}

.form__group-input {
    appearance: none;

    border: 1px solid #828282;

    width: 100%;

    padding: 10px 20px;

    &::placeholder {
        color: rgba(56, 62, 66, 1);
    }
}

.form__group-textarea {
    appearance: none;

    border: 1px solid #828282;

    width: 100%;
    height: 100px;

    padding: 10px 20px;

    &::placeholder {
        color: rgba(56, 62, 66, 1);
    }

    resize: none;
}

.form__group-submit {
    padding: 10px 15px;
    border-radius: 4px;
    text-transform: uppercase;
    color: var(--c-white);
    background-color: #F5A623;
}