// Categories
/////////////////////////////////////////////////////////////////////////////////

.archive__categories {
    margin-top: 50px;
}

.archive__contents {
    margin-top: 50px;
}

.posts__list {
    --item-per-line: 2;

    display: grid;
    grid-template-columns: repeat(var(--item-per-line), 1fr);
    row-gap: 50px;
    column-gap: 30px;

    @media(max-width: 767px){
        --item-per-line: 1;
    }
}