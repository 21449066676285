// Key numbers
///////////////////////////////////////////////////////////////////////////////////////////////////

.key__numbers-grid {
    --item-per-line: 3;

    display: grid;
    grid-template-columns: repeat(var(--item-per-line), 1fr);
    gap: 30px;

    @media(max-width: 991px){
        --item-per-line: 1;
    }
}

.key__numbers-item-icon {
    display: flex;
    align-items: center;
}

.key__numbers-item {
    display: flex;
    align-items: center;

    gap: 20px;
}

.key__numbers-item-text {
    font-size: 18px;
    line-height: 18px;
    margin-top: 10px;
}