// WYSIWYG
///////////////////////////////////////////////////////////////////////////////////////////////////

.editor {

    font-size: 1em;
    line-height: 1.2;

    h1, h2, h3, h4, h5, h6 {
        clear: both;
        color: #24292e;
        font-weight: 600;
        margin-top: 24px;
        margin-bottom: 15px;
    }

    h2 {
        font-size: 24px;
        padding-bottom: 0.3em;
        line-height: 1.25;
    }

    h3 {
        font-size: 18px;
        line-height: 25px;
    }

    h4 {
        font-size: 16px;
        line-height: 20px;
    }

    h5 {
        font-size: 14px;
        line-height: 17.5px;
    }

    p {
        margin-bottom: 1.5em;
    }

    strong {
        font-weight: bolder;
    }

    a {
        color: var(--c-link-color);
        font-weight: bolder;
    }

    blockquote {
        margin-bottom: 1em;
        padding: 0 1em;

        overflow-wrap: break-word;

        font-size: 1.5em;
        font-style: italic;
        line-height: 1.6;

        border-left: 6px solid #e3e3e3;
    }

    iframe {
        width: 100%;
        aspect-ratio: 16/9;
        height: auto;
    }

    ul {
        padding-left: 1rem;

        li {
            list-style: disc;
            margin-bottom: 1rem;
        }
    }

    ol {
        padding-left: 1rem;

        li {
            list-style: auto;
            margin-bottom: 1rem;
        }
    }

    .bg-grey {
        background-color: #F0F0F0;
        padding: 20px 35px;
        margin-bottom: 1rem;

        p {
            margin-bottom: 0;
        }
    }
}